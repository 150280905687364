import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [];

export const dictionary = {
		"/": [5],
		"/v1": [6,[2],[3]],
		"/v1/__components": [7,[2],[3]],
		"/v1/__pages": [8,[2],[3]],
		"/v1/__pages/surveys/loader": [9,[2],[3]],
		"/v1/error": [10,[2],[3]],
		"/v1/profile/completed": [11,[2,4],[3]],
		"/v1/profile/notice": [12,[2,4],[3]],
		"/v1/profile/pledge": [13,[2,4],[3]],
		"/v1/profile/quals": [~14,[2,4],[3]],
		"/v1/profile/quals/prev": [~15,[2,4],[3]],
		"/v1/profile/setup": [16,[2,4],[3]],
		"/v1/surveys": [~17,[2],[3]],
		"/v1/surveys/bounced": [~19,[2],[3]],
		"/v1/surveys/check_availability": [~20,[2],[3]],
		"/v1/surveys/completed": [~21,[2],[3]],
		"/v1/surveys/daily_limit": [22,[2],[3]],
		"/v1/surveys/hourly_limit": [23,[2],[3]],
		"/v1/surveys/match_error": [24,[2],[3]],
		"/v1/surveys/not_available": [25,[2],[3]],
		"/v1/surveys/not_qualify": [26,[2],[3]],
		"/v1/surveys/rejected": [~27,[2],[3]],
		"/v1/surveys/terminated": [~28,[2],[3]],
		"/v1/surveys/thank_you": [29,[2],[3]],
		"/v1/surveys/[id]": [~18,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';